<div class="container text-center">
  <h3>Forgot Password</h3>
  <div class="mt-3" role="modal-body">
    <form *ngIf="forgotPasswordForm !== undefined"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="onSubmitForgotPasswordForm()"
          autocomplete="off">
      <div class="form-group">
        <label class="required">Username or Email</label>
        <input type="text"
               class="form-control required"
               formControlName="userSearchTerm"
               placeholder="john.smith/john.smith@email.com">
      </div>
      <div *ngIf="failed"
           class="text-danger my-3">
        {{customerMessageError !== '' ? customerMessageError : 'Invalid credentials.'}}
      </div>
      <div>
        <button type="submit"
                class="btn btn-action px-5"
                [disabled]="forgotPasswordForm.invalid">Reset Password
        </button>
      </div>
    </form>
    <div>
      <button class="btn btn-link px-0"
              (click)="backToLogin()">
        Login
      </button>
    </div>
  </div>
</div>
