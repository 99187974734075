import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { UserService } from "app/shared/services";
import { ErrorsFormatterUtilService } from "app/shared/services/errors-formatter-util.service";
import { Router } from "@angular/router";

@Component({
  selector: 'reporter-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent {
  customerMessageError = '';
  failed = false;
  forgotPasswordForm: FormGroup;
  @Output() onBackToLogin = new EventEmitter<void>();

  constructor (
    private userService: UserService,
    private errorFormatter: ErrorsFormatterUtilService,
    private router: Router,
  ) {
    this.forgotPasswordForm = new FormGroup ( {
      userSearchTerm: new FormControl ( null, [ Validators.required ] ),
    } );
  }

   onSubmitForgotPasswordForm () {
     this.userService.sendVerificationCodeForUserSearchTerm ( this.forgotPasswordForm.value.userSearchTerm ).subscribe (response => {
       this.failed = false;
       this.customerMessageError = '';
       this.router.navigate(['/reset-password'], {
         queryParams: { userId: response.userId },
       });
     }, error => {
       const customError = this.errorFormatter.formatError ( error ).clientMessage;
       this.failed = true;
       this.customerMessageError = customError;
     } );
  }

  backToLogin () {
    this.failed = false;
    this.customerMessageError = '';
    this.onBackToLogin.emit();
  }
}
