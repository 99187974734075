<div class="container text-center">
  <div *ngIf="gbru && !sso401 && !showForgotPassword" class="my-5">
    Your session has expired, please login again to continue using the application.
  </div>
  <div *ngIf="!gbru && !sso401"
       class="form-group header-snippet"
       [innerHTML]="landingPageHeaderHtmlSnippet">
  </div>
  <div *ngIf="sso401 && !showForgotPassword">
    <p class="font-weight-bold">
      <span>A problem occurred verifying the user account. Please contact
      <a href="mailto:support@mvsc.com">support&#64;mvsc.com</a> for assistance.</span>
    </p>
  </div>
  <div *ngIf="uiConfig?.loginType === 'SSO'" class="form-group sso-button">
    <button class="btn btn-action sso-login-button"
            (click)="login.emit(true)">
      {{ uiConfig?.ssoButtonText }}
    </button>
  </div>
  <div *ngIf="uiConfig?.loginType === 'LEGACY' && !showForgotPassword"
       class="container text-center mt-3 login-form"
       role="modal-body">
    <form *ngIf="loginForm !== undefined"
          [formGroup]="loginForm"
          (ngSubmit)="submit.emit()"
          autocomplete="off">
      <div class="form-group">
        <label class="required">Username</label>
        <input type="text"
               class="form-control required"
               formControlName="userId"
               placeholder="john.smith">
      </div>
      <div class="form-group mb-1">
        <label class="required">Password</label>
        <input type="password"
               class="form-control required"
               formControlName="password"
               placeholder="Password"
               autocomplete="new-password">
      </div>
      <div *ngIf="failed"
           class="text-danger my-3">
        {{customerMessageError !== '' ? customerMessageError : 'Invalid credentials.'}}
      </div>
      <div>
        <button type="submit"
                class="btn btn-action px-5 mt-4"
                [disabled]="loginForm.invalid">Login
        </button>
      </div>
    </form>
    <div *ngIf="failed"
         class="">
      <button class="btn btn-link px-0" (click)="showForgotPassword = true">
        Forgot Password
      </button>
    </div>
  </div>

  <reporter-forgot-password [hidden]="!showForgotPassword" (onBackToLogin)="showForgotPassword = false">
  </reporter-forgot-password>

  <div class="footer-snippet mt-3"
       [innerHTML]="landingPageFooterHtmlSnippet"></div>
</div>
